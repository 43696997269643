import React from 'react'
import { cx, css } from 'linaria'

const INTRO_DETAILS = [
  `Once we're start to use application, desktop notification becomes a part of our lives. By allowing the web app to display notification at the right screen corner, it's easier to keep track latest updates without having to open the app itself.`,
  `For communication a like Messenger or Viber, we even get the chance to reply instantly. Convenient, isn't it?`,
  `Sure, desktop notification is cool.`,
  `But receiving a request to extend your pornhub membership showing up during a meeting call doesn't sound so appropriate.`,
]

export const Intro = () => {
  return (
    <div
      className={cx(
        'flex flex-col items-center text-secondary-black relative justify-center px-5',
        css`
          background: linear-gradient(180deg, rgba(215, 212, 251, 0.2) 0%, rgba(197, 209, 255, 0.2) 100%);
          background-size: cover;
          padding-top: 40px;
          padding-bottom: 20%;

          @screen md {
            padding-top: 120px;
          }
        `
      )}
    >
      <h2 className="text-section-title text-center mb-6">The Discomfort of Info Reveal</h2>
      <div
        className={cx(
          'w-full flex flex-col md:mb-20 mb-10 text-section-content text-center',
          css`
            @screen md {
              width: 80%;
              font-size: 21px;
            }
            @media (min-width: 1024px) {
              width: 760px;
            }
          `
        )}
      >
        {INTRO_DETAILS.map((content, idx) => (
          <p className="mb-4" key={`detail-${idx}`}>
            {content}
          </p>
        ))}
      </div>
    </div>
  )
}
