import React from 'react'
import Img from 'gatsby-image'
import { cx, css } from 'linaria'
import { useStaticQuery, graphql } from 'gatsby'

export const DoNotDisturb = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "auto-dnd/install-successfully.png" }) {
        childImageSharp {
          fluid(maxWidth: 440) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]

  return (
    <div className="bg-white py-10 md:py-20">
      <div className="container px-4">
        <div className="flex justify-between flex-wrap items-center -mx-4">
          <div
            className={cx(
              'mb-5 md:mb-0 md:w-1/2 px-4',
              css`
                max-width: 400px;
              `
            )}
          >
            <h3 className="mb-4 text-section-title">Do-not-Disturb? Checked!</h3>
            <p className="text-lg mb-2">{`Meet DnD - a mac app that keeps notifications stays right where it should.`}</p>
            <p className="text-lg">{`In other words, once you're conducting a screen sharing during video meeting, DnD automatically enables the do-not-disturb mode, which avoids the chance of notification to be shown on your desktop screen. Other attendees, such at your boss, co-workers or student, won't have to know your billing issue problem, your date at 7pm that night or your next road trip to Machu Picchu.`}</p>
          </div>
          <Img
            fluid={sources}
            alt="Get it back in No time"
            className={cx(
              'w-full md:w-1/2 px-4',
              css`
                max-width: 440px;
              `
            )}
          />
        </div>
      </div>
    </div>
  )
}
