import React from 'react'
import { cx, css } from 'linaria'
import { graphql } from 'gatsby'

import { AutoDNDApp as AppDetail } from '@components/contants'
import { Base } from '@ui/base'
import { Footer } from '@ui/footer'
import { Header } from '@ui/header'
import { CheckOn } from '@ui/check-on'
import { DownloadNow } from '@ui/download-now'

import banner from '@/images/auto-dnd/banner.jpg'
import boxPng from '@/images/auto-dnd/box.png'
import { Jumbotron } from '@components/ui/jumbotron'
import { Intro } from '@components/auto-dnd/intro'
import { DoNotDisturb } from '@components/auto-dnd/do-not-disturb'
import { InANutshell } from '@components/auto-dnd/in-a-nutshell'
import demoVideo from '@/assets/video/demo-auto-dnd.mp4'
import DemoOnMac from '@ui/demo-on-mac'
import { Compatibility } from '@components/auto-dnd/compatibility'
import Seo from '@components/seo-detail-page'

const AutoDNDDetailsPage = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)
  return (
    <>
      <Seo {...AppDetail} video={demoVideo} images={images} />
      <Base>
        <Header className="top-0 sticky w-full z-50" />
        <Jumbotron
          {...AppDetail}
          bgClass={css`
            background-image: url(${banner}),
              linear-gradient(180deg, rgba(5, 0, 230, 0.8) 0%, rgba(5, 0, 242, 0.8) 100%);
            background-size: cover;
          `}
        />
        <Intro />
        <div
          className={cx(
            'container px-4 relative z-10 md:pb-20 pb-10',
            css`
              margin-top: -20%;
            `
          )}
        >
          <DemoOnMac src={demoVideo} />
        </div>
        <DoNotDisturb />
        <InANutshell />
        <Compatibility />
        <CheckOn {...AppDetail} />
        <DownloadNow className="bg-background-light" {...AppDetail} boxImage={boxPng} />
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/auto-dnd/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

export default AutoDNDDetailsPage
