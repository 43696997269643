import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { cx } from 'linaria'

import ZoomIcon from '@/images/auto-dnd/zoom.png'
import HangoutIcon from '@/images/auto-dnd/hangout.png'
import SlackIcon from '@/images/auto-dnd/slack.png'
import SkypeIcon from '@/images/auto-dnd/skype.png'

export const Compatibility = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "auto-dnd/compatibility.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <section className={cx('section')}>
      <div className="container px-4">
        <div className={cx('flex justify-between flex-wrap items-center -mx-4')}>
          <div className={cx('md:w-1/2 max-w-98 mb-5 md:mb-0 px-4')}>
            <h2 className="text-section-title mb-4">Compatibility</h2>
            <p className="text-lg mb-4">Work with macOS Sierra 10.12 or later.</p>
            <p className="text-lg mb-8">{`Support video meeting apps with screen sharing such as Zoom, Google Hangout, Slack, Skype,.. and other alternatives.`}</p>
            <ul className="list-reset flex -mx-4 flex-wrap">
              <li className="px-4">
                <img src={ZoomIcon} width="60" alt="" />
              </li>
              <li className="px-4">
                <img src={HangoutIcon} width="60" alt="" />
              </li>
              <li className="px-4">
                <img src={SlackIcon} width="60" alt="" />
              </li>
              <li className="px-4">
                <img src={SkypeIcon} width="60" alt="" />
              </li>
            </ul>
          </div>
          <Img fluid={sources} alt="Modify as you use" className="max-w-90 w-full" />
        </div>
      </div>
    </section>
  )
}
