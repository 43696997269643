import React from 'react'
import { cx, css } from 'linaria'

import bg from '@/images/auto-dnd/bg-in-a-nutshell.jpg'
import { ReactComponent as SvgNotification } from '@/images/auto-dnd/notification.svg'
import { ReactComponent as CheckIconSvg } from '@components/svg/check-icon.svg'

const list = [
  'Hide desktop notification during screen sharing',
  'Disable automatically when meeting is ended',
  'Avoid revealing subtle information',
  'Protect notification privacy',
]

export const InANutshell = () => {
  return (
    <section
      className={cx(
        'section text-white mx-auto',
        css`
          max-width: 72.5rem;
          background: url(${bg}) no-repeat center top;
        `
      )}
    >
      <div className={cx('container px-4')}>
        <div className="flex justify-between flex-wrap items-center -mx-4 flex-row-reverse">
          <div
            className={cx(
              'mb-5 md:mb-0 md:w-1/2 px-4 w-full md:w-1/2',
              css`
                max-width: 400px;
              `
            )}
          >
            <h3 className="mb-4 text-section-title">In a Nutshell</h3>
            <ul className="list-reset">
              {list.map((item, idx) => (
                <li key={item} className={cx('flex', idx !== 0 && 'mt-4')}>
                  <CheckIconSvg width="18" height="18" viewBox="0 0 24 24" className={cx('flex-none mt-1')} />
                  <span
                    className={cx(
                      'block text-lg',
                      css`
                        margin-left: 17px;
                      `
                    )}
                  >
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/2 px-4 items-center justify-center">
            <SvgNotification className="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  )
}
